import type { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { colors } from '@/constants/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  // eslint-disable-next-line @typescript-eslint/no-shadow
  interface ThemeOptions {
    altBackground?: boolean;
    // @ts-ignore
    palette?: {
      primary?: {
        main?: string;
      };
      secondary?: {
        main?: string;
      };
      background?: {
        default?: string;
        paper?: string;
      };
    };
  }

  export interface CommonColors {
    brightPurple: string;
    lila: string;
    limeGreen: string;
    navy: string;
    magenta: string;
    slate: string;
    slate60: string;
    mistyDay: string;
    white: string;
    black: string;
    dawn: string;
    smoke: string;
    folly: string;
    silver: string;
    error: string;
    success: string;
    warning: string;
    info: string;
    blue: string;
    lightBlue: string;
    darkPurple: string;
    silverFoil: string;
    cream: string;
    creamLight: string;
    green: string;
    darkGreen: string;
    lightGreen: string;
    lighterGreen: string;
    purpleFig: string;
    cucumber: string;
    green60: string;
    blueberry: string;
  }
}

export const themeOptions: ThemeOptions = {
  altBackground: false,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiRadio: {
      styleOverrides: {
        root: {
          width: '40px',
          minWidth: '40px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          height: '20px',
          width: '20px',
          maxHeight: '20px',
          maxWidth: '20px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          background: colors.white,
          color: colors.slate60,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: colors.white,
          fieldset: {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.green,
          fontWeight: 700,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: '3px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '2px',
          marginTop: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            borderLeft: `3px solid ${colors.slate}`,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '.MuiAutocomplete-option[aria-selected="true"]': {
            borderLeft: `3px solid ${colors.slate}`,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.slate,
    },
    secondary: {
      main: colors.navy,
    },
    background: {
      default: colors.silver,
      paper: colors.white,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    common: {
      brightPurple: colors.brightPurple,
      limeGreen: colors.limeGreen,
      navy: colors.navy,
      magenta: colors.magenta,
      slate: colors.slate,
      slate60: colors.slate60,
      mistyDay: colors.mistyDay,
      white: colors.white,
      black: colors.black,
      dawn: colors.dawn,
      smoke: colors.smoke,
      folly: colors.folly,
      silver: colors.silver,
      error: colors.error,
      warning: colors.warning,
      success: colors.success,
      info: colors.info,
      blue: colors.blue,
      lightBlue: colors.lightBlue,
      darkPurple: colors.darkPurple,
      silverFoil: colors.silverFoil,
      green: colors.green,
      cucumber: colors.cucumber,
      purpleFig: colors.purpleFig,
      darkGreen: colors.darkGreen,
      lightGreen: colors.lightGreen,
      green60: colors.green60,
    },
    text: {
      secondary: colors.slate,
      primary: colors.slate,
    },
    action: {
      disabledOpacity: 0.7,
      disabledBackground: colors.lightGreen,
      disabled: colors.white,
    },
  },
  typography: {
    body1: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 14,
    },
    h1: {
      fontFamily: 'Marcellus',
      fontSize: '26px',
    },
    // @ts-ignore
    body3: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 12,
      lineHeight: '18px',
    },
    strong: {
      color: colors.green,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 14,
      lineHeight: '16px',
    },
    button: {
      fontFamily: 'Plus Jakarta Sans',
      fontStyle: 'normal',
    },
  },
};

export default createTheme(themeOptions);
