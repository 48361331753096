import type { DateTime } from 'luxon';
import React, { createContext, useContext, useReducer } from 'react';

import {
  APPOINTMENT_ALREADY_BOOKED,
  APPOINTMENT_NOT_AVAILABLE,
} from '@/constants/constants';
import { bookAppointmentRequest } from '@/core/api';
import type {
  ICalendarPeriod,
  IInsuranceFormPayloadProps,
  ILead,
  IMembershipOption,
  IPatientData,
  IQuizPayloadProps,
  IReferrer,
} from '@/models/Types';

export type BookedAppointment = {
  date: DateTime;
  practitioner_name: string | null;
  patient_name: string;
  ticket_id: string | null;
  links?: {
    iCalendar?: string;
    googleCalendar?: string;
    outlookCalendar?: string;
  };
};

type Appointment = {
  slots_id: string;
  providerId?: string;
  start_date: DateTime;
  startDate?: string;
  label?: string;
  practitioner_name?: string;
};

export type AppointmentDetails = {
  email?: string;
  reason?: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  phoneNumber: string;
  dateOfBirth: string;
  zipCode: string;
  referral: string;
  referral_other?: string;
  referral_source_physician_practice?: string;
  willVerifyInsurance?: string | boolean;
  consentToContactViaSMS?: boolean;
  addressState?: string;
  insuranceCompany?: string;
};

/* typescript definitions */
type Action =
  | { type: 'UPDATE_BASIC_INFO'; data: object }
  | { type: 'UPDATE_DETAILS'; data: AppointmentDetails }
  | { type: 'RESET_DETAILS'; data: any }
  | { type: 'DC_DETAILS_SUBMITTED'; data?: boolean }
  | { type: 'SET_BOOKED_APPOINTMENT'; appointment: BookedAppointment }
  | { type: 'UPDATE_APPOINTMENT'; appointment: Appointment | null }
  | {
      type: 'BOOK_APPOINTMENT_START';
    }
  | {
      type: 'BOOK_APPOINTMENT_SUCCESS';
      appointment: Appointment | null;
    }
  | {
      type: 'BOOK_APPOINTMENT_ERROR_RESET';
    }
  | {
      type: 'BOOK_APPOINTMENT_ERROR';
      code?: string;
    }
  | {
      type: 'PICKED_PROVIDER_ID';
      code?: string;
    }
  | {
      type: 'PICKED_PROVIDER_FIRST_SLOT';
      data?: DateTime;
    }
  | {
      type: 'PICKED_PROVIDER_NAME';
      code?: string;
    }
  | {
      type: 'QUIZ_STEP';
      code?: string;
    }
  | {
      type: 'NO_MATCH_REASON';
      data?: string;
    }
  | {
      type: 'REFERRER_DATA';
      data?: IReferrer;
    }
  | {
      type: 'INSURANCE_PAYLOAD';
      data?: IInsuranceFormPayloadProps;
    }
  | {
      type: 'INSURANCE_PAYLOAD_START';
    }
  | {
      type: 'INSURANCE_FRONT_IMAGE';
      data?: any;
    }
  | {
      type: 'INSURANCE_BACK_IMAGE';
      data?: any;
    }
  | {
      type: 'INSURANCE_PAYLOAD_END';
    }
  | {
      type: 'INSURANCE_TICKET_ID';
      data?: string;
    }
  | {
      type: 'QUIZ_PAYLOAD';
      data?: object;
    }
  | {
      type: 'INSURANCE_STEP';
      code?: string;
    }
  | {
      type: 'FLOW_PICKED';
      code?: string;
    }
  | {
      type: 'STRIPE_TOKEN';
      data?: string;
    }
  | {
      type: 'COUPON_CODE_USED';
      data?: string;
    }
  | {
      type: 'SET_CALENDAR_START';
      data?: ICalendarPeriod;
    }
  | {
      type: 'TOGGLE_CHOOSE_SESSION_SCREEN';
      data?: boolean;
    }
  | {
      type: 'RD_BUTTON_CLICKED';
      data?: string;
    }
  | {
      type: 'SET_DIETITIANS_FILTERED';
      data?: any;
    }
  | {
      type: 'PICKED_MEMBERSHIP';
      data?: IMembershipOption;
    }
  | {
      type: 'SAVE_LEAD';
      data: string;
    }
  | {
      type: 'SAVE_LEAD_EMAIL';
      data: string;
    }
  | {
      type: 'SAVE_LEAD_USER';
      data: ILead;
    }
  | {
      type: 'SAVE_LEAD_USER_ID';
      data: string;
    }
  | {
      type: 'SAVE_PATIENT_DATA';
      data: IPatientData;
    };

type Dispatch = (action: {
  data?: any | null;
  code?: string | number | null;
  appointment?: any | null;
  type: string;
}) => void;

type State = {
  appointment?: Appointment;
  details?: AppointmentDetails;
  bookedAppointment?: BookedAppointment;
  bookAppointmentLoading?: boolean;
  bookAppointmentError?: boolean;
  bookAppointmentErrorCode?: string | null;
  providerId?: string | null;
  providerName?: string | null;
  providerFirstSlot?: DateTime;
  pickedMembership?: IMembershipOption | null;
  userToken?: string;
  leadEmailDC?: string;
  leadUser?: ILead;
  userId?: string;
  quizStep?: number;
  noMatchReason?: string;
  dcDetailsSubmitted?: boolean;
  referrer?: IReferrer;
  quizPayload?: IQuizPayloadProps;
  insurancePayload?: IInsuranceFormPayloadProps;
  insurancePayloadLoading?: boolean;
  insuranceTicketId?: string;
  insuranceFrontImage?: string;
  insuranceBackImage?: string;
  insuranceStep?: number;
  flowPicked?: string;
  patientData?: IPatientData;
  stripeToken?: string;
  couponCode?: string;
  calendarStart?: any;
  chooseSessionScreen?: boolean;
  rdButtonClickedId?: string;
  dietitiansFiltered?: any;
};

type AppStateProviderProps = { children: React.ReactNode };

const initialState = {
  appointment: null,
  details: {},
  insurancePayloadLoading: true,
};

/* create context */
const AppStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

/* reducer used for actions */
function appStateReducer(state: State, action: Action) {
  switch (action.type) {
    case 'UPDATE_BASIC_INFO': {
      return { ...state, details: { ...state.details, ...action.data } };
    }
    case 'UPDATE_DETAILS': {
      return { ...state, details: { ...state.details, ...action.data } };
    }
    case 'RESET_DETAILS': {
      return { ...state, details: null };
    }
    case 'DC_DETAILS_SUBMITTED': {
      return { ...state, dcDetailsSubmitted: action.data };
    }
    case 'UPDATE_APPOINTMENT': {
      return { ...state, appointment: action.appointment };
    }
    case 'BOOK_APPOINTMENT_START': {
      return {
        ...state,
        bookAppointmentLoading: true,
        bookAppointmentError: false,
        bookAppointmentErrorCode: null,
      };
    }
    case 'BOOK_APPOINTMENT_SUCCESS': {
      return {
        ...state,
        bookAppointmentLoading: false,
        bookAppointmentError: false,
        bookAppointmentErrorCode: null,
        bookedAppointment: action.appointment,
      };
    }
    case 'BOOK_APPOINTMENT_ERROR': {
      return {
        ...state,
        bookAppointmentLoading: false,
        bookAppointmentError: true,
        bookAppointmentErrorCode: action.code,
      };
    }
    case 'BOOK_APPOINTMENT_ERROR_RESET': {
      return {
        ...state,
        bookAppointmentLoading: false,
        bookAppointmentError: false,
        bookAppointmentErrorCode: null,
      };
    }
    case 'PICKED_PROVIDER_ID': {
      return {
        ...state,
        providerId: action.code,
      };
    }
    case 'PICKED_PROVIDER_FIRST_SLOT': {
      return {
        ...state,
        providerFirstSlot: action.data,
      };
    }
    case 'PICKED_PROVIDER_NAME': {
      return {
        ...state,
        providerName: action.code,
      };
    }
    case 'QUIZ_STEP': {
      return {
        ...state,
        quizStep: action.code,
      };
    }
    case 'NO_MATCH_REASON': {
      return {
        ...state,
        noMatchReason: action.data,
      };
    }
    case 'REFERRER_DATA': {
      return {
        ...state,
        referrer: action.data,
      };
    }
    case 'INSURANCE_FRONT_IMAGE': {
      return {
        ...state,
        insuranceFrontImage: action.data,
      };
    }
    case 'INSURANCE_BACK_IMAGE': {
      return {
        ...state,
        insuranceBackImage: action.data,
      };
    }
    case 'INSURANCE_PAYLOAD': {
      return {
        ...state,
        insurancePayload: action.data,
      };
    }
    case 'INSURANCE_PAYLOAD_START': {
      return {
        ...state,
        insurancePayloadLoading: true,
      };
    }
    case 'INSURANCE_PAYLOAD_END': {
      return {
        ...state,
        insurancePayloadLoading: false,
      };
    }
    case 'INSURANCE_TICKET_ID': {
      return {
        ...state,
        insuranceTicketId: action.data,
      };
    }
    case 'QUIZ_PAYLOAD': {
      return {
        ...state,
        quizPayload: action.data,
      };
    }
    case 'INSURANCE_STEP': {
      return {
        ...state,
        insuranceStep: action.code,
      };
    }
    case 'FLOW_PICKED': {
      return {
        ...state,
        flowPicked: action.code,
      };
    }
    case 'STRIPE_TOKEN': {
      return {
        ...state,
        stripeToken: action.data,
      };
    }
    case 'COUPON_CODE_USED': {
      return {
        ...state,
        couponCode: action.data,
      };
    }
    case 'SET_CALENDAR_START': {
      return {
        ...state,
        calendarStart: action.data,
      };
    }
    case 'TOGGLE_CHOOSE_SESSION_SCREEN': {
      return {
        ...state,
        chooseSessionScreen: action.data,
      };
    }
    case 'RD_BUTTON_CLICKED': {
      return {
        ...state,
        rdButtonClickedId: action.data,
      };
    }
    case 'SET_DIETITIANS_FILTERED': {
      return {
        ...state,
        dietitiansFiltered: action.data,
      };
    }
    case 'PICKED_MEMBERSHIP': {
      return {
        ...state,
        pickedMembership: action.data,
      };
    }
    case 'SAVE_LEAD': {
      return {
        ...state,
        userToken: action.data,
      };
    }
    case 'SAVE_LEAD_EMAIL': {
      return {
        ...state,
        leadEmailDC: action.data,
      };
    }
    case 'SAVE_LEAD_USER': {
      return {
        ...state,
        leadUser: action.data,
      };
    }
    case 'SAVE_LEAD_USER_ID': {
      return {
        ...state,
        userId: action.data,
      };
    }
    case 'SAVE_PATIENT_DATA': {
      return {
        ...state,
        patientData: action.data,
      };
    }
    default: {
      throw new Error(`Unhandled action type in appStateReducer.`);
    }
  }
}

export interface IBookAppointmentRequestType {
  details: AppointmentDetails;
  appointment: Appointment;
  type: string;
  time_zone: string;
  user_id?: string;
  insurance_ticket_number?: string;
}

const bookAppointment = async (
  dispatch: Dispatch,
  bookingData: IBookAppointmentRequestType,
  router?: any
) => {
  dispatch({ type: 'BOOK_APPOINTMENT_ERROR_RESET' });
  dispatch({ type: 'BOOK_APPOINTMENT_START' });

  try {
    const appointmentResponse = await bookAppointmentRequest(bookingData);
    const appointmentResponseJson = await appointmentResponse.json();

    const bookedAppointment: BookedAppointment = {
      date: bookingData.appointment.start_date,
      practitioner_name: appointmentResponseJson.providerName || null,
      patient_name: `${bookingData.details.firstName} ${bookingData.details.lastName}`,
      ticket_id: appointmentResponseJson.ticketId,
      links: appointmentResponseJson.links,
    };

    if (appointmentResponse.status === 200) {
      dispatch({
        type: 'BOOK_APPOINTMENT_SUCCESS',
        appointment: bookedAppointment,
      });
    } else {
      if (appointmentResponseJson?.code === APPOINTMENT_NOT_AVAILABLE) {
        dispatch({
          type: 'BOOK_APPOINTMENT_ERROR',
          code: APPOINTMENT_NOT_AVAILABLE,
        });
        dispatch({
          type: 'UPDATE_APPOINTMENT',
          appointment: null,
        });

        router.push('/discovery/schedule/pick-slot?discoveryCall=true');
      } else if (appointmentResponseJson?.code === APPOINTMENT_ALREADY_BOOKED) {
        dispatch({
          type: 'BOOK_APPOINTMENT_ERROR',
          code: APPOINTMENT_ALREADY_BOOKED,
        });
        dispatch({
          type: 'UPDATE_APPOINTMENT',
          appointment: null,
        });
      } else {
        dispatch({
          type: 'BOOK_APPOINTMENT_ERROR',
        });
      }

      throw new Error('Failed to book appointment');
    }

    return true;
  } catch (error: any) {
    console.error('Error: ', error);
    return false;
  }
};

/* provider used to wrap components with context  */
const AppStateProvider = ({ children }: AppStateProviderProps) => {
  // @ts-ignore
  const [state, dispatch] = useReducer(appStateReducer, initialState);

  const value = { state, dispatch };

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

/* used in components to access state */
const useAppState = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
};

export { AppStateContext, AppStateProvider, bookAppointment, useAppState };
