export const AppConfig = {
  env: process.env.NEXT_PUBLIC_NODE_ENV || 'development',
  dataDogClientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  dataDogApplicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  port: process.env.NEXT_PUBLIC_PORT || 80,
  site_name: 'Culina Health',
  title: 'Culina Health - Start Your Journey',
  description:
    'Find out if Culina Health is right for you. Book your first appointment with a dietitian, or start with a free discovery call',
  locale: 'en',
};
