import type { CommonColors } from '@mui/material';

export const colors: CommonColors = {
  brightPurple: '#934EFF',
  lila: '#CAC9F3',
  limeGreen: '#F3FFA5',
  navy: '#001C3F',
  magenta: '#BB3AFF',
  slate: '#41556B',
  slate60: '#41556B99',
  mistyDay: '#F6F4F6',
  white: '#FFFFFF',
  black: '#000000',
  dawn: '#FFFCFB',
  smoke: '#FFF5F2',
  folly: '#F50057',
  silver: '#F5F5F5',
  error: '#CA1515',
  success: '#66BF80',
  warning: '#FFBA50',
  info: '#3276E7',
  blue: '#344F64',
  lightBlue: '#4B88EE',
  darkPurple: '#762AEE',
  silverFoil: '#727F88',
  cream: '#F8F7EF',
  creamLight: '#FBFAF5',
  green: '#3F6655',
  lightGreen: '#3F66551A',
  lighterGreen: '#B2C2BB',
  darkGreen: '#20332B',
  purpleFig: '#9461BC',
  cucumber: '#B6CCAE',
  green60: 'rgba(63, 102, 85, 0.6)',
  blueberry: '#5E69BC',
};
