// istanbul ignore file
import '@fontsource/plus-jakarta-sans/800.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/marcellus';

import { createGlobalStyle } from 'styled-components';

import { colors } from '@/constants/colors';

interface GlobalStyleProps {
  altBackground?: string;
}

export default createGlobalStyle<GlobalStyleProps>`
     *,
    ::before {
        box-sizing: border-box;
    }
    strong {
      font-weight: 700;
    }
    body {
        margin: 0;
        padding-top: 46px;
        background-color: ${(props) =>
          props.altBackground ? colors.mistyDay : colors.cream};
        font-family: 'Plus Jakarta Sans', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: ${colors.slate};
    }
    p {
      color: ${colors.slate};
    }
    a {
      text-decoration: underline;
      color: #3276E7;
    }
    .css-6hp17o-MuiList-root-MuiMenu-list {
      overflow-y: scroll;
      max-height: 340px;
    }
    .css-7ohbso-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      top: 351px !important;
    }

     .slick-disabled {
       display: none !important;
     }

     .lds-dual-ring {
       display: inline-block;
     }
     .lds-dual-ring:after {
       content: " ";
       display: block;
       width: 20px;
       height: 20px;
       border-radius: 50%;
       border: 3px solid;
       border-color: ${colors.green} transparent;
       animation: lds-dual-ring 1.2s linear infinite;
     }
     @keyframes lds-dual-ring {
       0% {
         transform: rotate(0deg);
       }
       100% {
         transform: rotate(360deg);
       }
     }
     :focus-visible {
       outline: none;
     }
     ::-webkit-scrollbar {
       width: 7px;
     }
     ::-webkit-scrollbar-track {
       background-color: transparent;
     }
     ::-webkit-scrollbar-thumb {
       background-color: rgba(63, 102, 85, 0.6);
       border-radius: 4px;
     }
     ::-webkit-scrollbar-corner {
       background: rgba(0,0,0,0);
    }
`;
