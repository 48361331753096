// istanbul ignore file
import { datadogRum } from '@datadog/browser-rum';

import { AppConfig } from '@/utils/AppConfig';

import PackageJson from '../../package.json';

export function initDatadog(): void {
  if (process.env.NEXT_PUBLIC_DATADOG_ENABLED === 'true') {
    datadogRum.init({
      applicationId: AppConfig.dataDogApplicationId || '',
      clientToken: AppConfig.dataDogClientToken || '',
      site: 'datadoghq.com',
      service: 'culina-start',
      env: AppConfig.env,
      version: PackageJson.version,
      allowedTracingUrls: ['https://prod-cdn.culinahealth.com'],
      defaultPrivacyLevel: 'mask-user-input',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
    });

    datadogRum.startSessionReplayRecording();
  }
}
