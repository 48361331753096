export const COMPANY_NAME = 'Culina Health';

// Links & E-mails
export const CULINA_TC_LINK = 'https://culinahealth.com/t-and-c';
export const CULINA_HIPAA_LINK =
  'https://culinahealth.com/hipaa-privacy-policy';
export const CULINA_BLOG_LINK = 'https://culinahealth.com/blog/';
export const CULINA_PRIVACY_POLICY_LINK =
  'https://culinahealth.com/privacy-notice';
export const CULINA_INFO_EMAIL = 'info@culinahealth.com';
export const CULINA_INFO_EMAIL_LINK = `mailto:${CULINA_INFO_EMAIL}`;
export const CULINA_INFO_PHONE = '917-647-1665';
export const CULINA_INFO_PHONE_LINK = 'tel:+19176471665';
export const HEALTHIE_LINK = 'www.gethealthie.com';
export const CULINA_INSTAGRAM = 'https://www.instagram.com/culinahealth/';
export const CULINA_SITE = 'https://culinahealth.com/';
export const FIVE_RECIPES_LINK =
  'https://drive.google.com/file/d/1UPWLvl8QgWu9LVerouTs0Te85mMMnb6W/view';

// Form constants
export const HEIGHT_OPTIONS = [
  { feetIn: `4'0"`, inches: '48' },
  { feetIn: `4'1"`, inches: '49' },
  { feetIn: `4'2"`, inches: '50' },
  { feetIn: `4'3"`, inches: '51' },
  { feetIn: `4'4"`, inches: '52' },
  { feetIn: `4'5"`, inches: '53' },
  { feetIn: `4'6"`, inches: '54' },
  { feetIn: `4'7"`, inches: '55' },
  { feetIn: `4'8"`, inches: '56' },
  { feetIn: `4'9"`, inches: '57' },
  { feetIn: `4'10"`, inches: '58' },
  { feetIn: `4'11"`, inches: '59' },
  { feetIn: `5'0"`, inches: '60' },
  { feetIn: `5'1"`, inches: '61' },
  { feetIn: `5'2"`, inches: '62' },
  { feetIn: `5'3"`, inches: '63' },
  { feetIn: `5'4"`, inches: '64' },
  { feetIn: `5'5"`, inches: '65' },
  { feetIn: `5'6"`, inches: '66' },
  { feetIn: `5'7"`, inches: '67' },
  { feetIn: `5'8"`, inches: '68' },
  { feetIn: `5'9"`, inches: '69' },
  { feetIn: `5'10"`, inches: '70' },
  { feetIn: `5'11"`, inches: '71' },
  { feetIn: `6'0"`, inches: '72' },
  { feetIn: `6'1"`, inches: '73' },
  { feetIn: `6'2"`, inches: '74' },
  { feetIn: `6'3"`, inches: '75' },
  { feetIn: `6'4"`, inches: '76' },
  { feetIn: `6'5"`, inches: '77' },
  { feetIn: `6'6"`, inches: '78' },
  { feetIn: `6'7"`, inches: '79' },
  { feetIn: `6'8"`, inches: '80' },
  { feetIn: `6'9"`, inches: '81' },
  { feetIn: `6'10"`, inches: '82' },
  { feetIn: `6'11"`, inches: '83' },
  { feetIn: `7'0"`, inches: '84' },
];

export const GENDER_OPTIONS_GENERAL = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
];

export const GENDER_OPTIONS_HOLDER = [
  { name: 'Male', value: 'ph_male' },
  { name: 'Female', value: 'ph_female' },
];

export const PATIENT_RELATED_OPTIONS = [
  {
    name: 'Self',
    value: 'ip_rel_self',
  },
  {
    name: 'Spouse',
    value: 'ip_rel_spouse',
  },
  {
    name: 'Child',
    value: 'ip_rel_child',
  },
  {
    name: 'Other',
    value: 'ip_rel_other',
  },
];

// Other
export const MIN_NUMBER_OF_DAYS_BEFORE_BOOKING = 2;
export const MIN_NUMBER_OF_DAYS_BEFORE_BOOKING_DTI_INSURANCE_CLOSEKNIT = 3;
export const MIN_NUMBER_OF_DAYS_BEFORE_BOOKING_DTI_INSURANCE = 2;
export const MIN_NUMBER_OF_DAYS_BEFORE_BOOKING_DTI_SP = 1;
export const AVAILABILITIES_PERIOD_CHECK_IN_WEEKS = 3;
export const MAX_FILE_SIZE_BYTES = 25 * 1024 * 1024; // 25mb
export const APPOINTMENT_NOT_AVAILABLE = 'APPOINTMENT_NOT_AVAILABLE';
export const APPOINTMENT_ALREADY_BOOKED = 'APPOINTMENT_ALREADY_BOOKED';
export const RESTRICTED_PROVIDER_ERROR = 'RESTRICTED_PROVIDER_ERROR';

export const APPOINTMENT_ALREADY_BOOKED_MESSAGE =
  'Hello, we see you already have a booked appointment! Please check your original confirmation email for further instructions on how to reschedule this call. If you need further assistance, please call our office at';

export const RESTRICTED_PROVIDER_ERROR_MESSAGE =
  'Hello, we see that the provider you have selected is not available based on your referral information. Please call our office at';

export const BOOKING_TYPES = {
  DISCOVERY_CALL: 'discovery-call',
  INITIAL_CONSULTATION: 'initial-consultation',
};

export const INSURANCE_STEPS = {
  UPLOAD_FORM: 1,
  DETAILS_FORM: 2,
  INSURANCE_HOLDER_FORM: 3,
  SUCCESS: 4,
};

export const INITIAL_BOOKING_STEPS = {
  PICK_RD: 1,
  SELECT_MEMBERSHIP: 2,
  PAYMENT: 3,
  SUCCESS: 4,
  UNMATCHED: 5,
};

export const LANDING_PAGE_OPTIONS = [
  {
    value: 'lpg_dti',
    name: 'Book your first session',
    description: `We'll ask about your reason for visit, location, preferred approach, and schedule, and find a Registered Dietitian that's right for you.`,
  },
];

export const TIMEOUTS = {
  XXSMALL: 300,
  XSMALL: 900,
  SMALL: 1200,
  MEDIUM: 2000,
  LARGE: 3000,
  XLARGE: 4200,
  XXLARGE: 6500,
};

export const LONG_DATE_FORMAT = 'cccc, MMMM dd, yyyy';
export const BIRTHDAY_DATE_FORMAT = 'yyyy/MM/dd';
export const TIME_FORMAT_US = 'h:mm a';

// Messages
export const INVALID_TOKEN = 'Invalid user token!';
export const INVALID_TICKET = 'Ticket ID is missing.';
export const ERROR_MESSAGES = {
  MAX_STRING: 'Your entry is too long, please make it shorter.',
  REQUIRED_FIELD: 'Field is required.',
  REQUIRED_FIELD_SELECT: 'Please select an option.',
  GENERAL: 'Something went wrong. Please try again.',
  DATE_FIELD: 'Please enter a valid date (MM/DD/YYYY).',
  ZIP_FIELD: 'Invalid zip.',
  PROMO_CODE: 'Invalid promo code.',
  EMAIL_FIELD: 'Enter a valid email address.',
  PHONE_FIELD: 'Invalid phone number.',
};

export const SOURCE_KEYS = [
  'src_google_ad',
  'src_facebook_ad',
  'src_instagram_ad',
  'src_youtube',
  'src_reddit',
  'src_google_search',
  'src_social_media',
  'src_tv_appearance',
  'src_podcast',
  'src_link_on_other_website',
  'src_email',
  'src_physician',
  'src_friend',
  'src_event',
  'src_zocdoc',
  'src_insurance_company',
  'src_other',
  'src_closeknit',
] as const;

export type ReferralSourceKey = typeof SOURCE_KEYS[number];
export type ReferralSource = {
  key: ReferralSourceKey;
  value: ReferralSourceKey;
  label: string;
};

export const REFERRAL_OPTIONS: ReferralSource[] = [
  {
    key: 'src_google_ad',
    value: 'src_google_ad',
    label: 'Google ad',
  },
  {
    key: 'src_facebook_ad',
    value: 'src_facebook_ad',
    label: 'Facebook ad',
  },
  {
    key: 'src_instagram_ad',
    value: 'src_instagram_ad',
    label: 'Instagram',
  },
  {
    key: 'src_youtube',
    value: 'src_youtube',
    label: 'YouTube',
  },
  {
    key: 'src_reddit',
    value: 'src_reddit',
    label: 'Reddit',
  },
  {
    key: 'src_google_search',
    value: 'src_google_search',
    label: 'Search engine',
  },
  {
    key: 'src_social_media',
    value: 'src_social_media',
    label: 'Social media post',
  },
  {
    key: 'src_tv_appearance',
    value: 'src_tv_appearance',
    label: 'TV',
  },
  {
    key: 'src_podcast',
    value: 'src_podcast',
    label: 'Podcast',
  },
  {
    key: 'src_link_on_other_website',
    value: 'src_link_on_other_website',
    label: 'Link on other website',
  },
  {
    key: 'src_email',
    value: 'src_email',
    label: 'Email',
  },
  {
    key: 'src_physician',
    value: 'src_physician',
    label: 'Physician',
  },
  {
    key: 'src_friend',
    value: 'src_friend',
    label: 'Friend or family member',
  },
  {
    key: 'src_event',
    value: 'src_event',
    label: 'Event or conference',
  },
  {
    key: 'src_zocdoc',
    value: 'src_zocdoc',
    label: 'Zocdoc',
  },
  {
    key: 'src_insurance_company',
    value: 'src_insurance_company',
    label: 'Insurance company',
  },
  {
    key: 'src_other',
    value: 'src_other',
    label: 'Other',
  },
];

export const REFERRAL_OPTIONS_DTI: ReferralSource[] = [
  {
    key: 'src_closeknit',
    value: 'src_closeknit',
    label: 'Closeknit',
  },
  ...REFERRAL_OPTIONS,
];

export const REFERRAL_SOURCE_DETAILS_LABELS: Partial<
  Record<ReferralSourceKey, string>
> = {
  src_podcast: 'Please specify podcast *',
  src_link_on_other_website: 'Please specify website *',
  src_event: 'Please specify event or conference *',
  src_other: 'Please Specify *',
};

export enum CustomGroups {
  custom_group_cdc,
}

export const CUSTOM_GROUP_REASONS: Record<keyof typeof CustomGroups, string> = {
  custom_group_cdc: 'rfv_gastrointestinal_health',
};

export const DCSteps = {
  CHOOSE_DATE_TIME: 10,
  PATIENT_INFO: 20,
  INSURANCE_INFO: 30,
  BOOKING_CONFIRMED: 40,
};

export const getDCSteps = (step: number) => [
  {
    id: 'date-time',
    label: 'Date & Time',
    active: step === DCSteps.CHOOSE_DATE_TIME,
    complete: step > DCSteps.CHOOSE_DATE_TIME,
    step: 1,
  },
  {
    id: 'patient-info',
    label: 'Patient Info',
    active: step === DCSteps.PATIENT_INFO,
    complete: step > DCSteps.PATIENT_INFO,
    step: 2,
  },
  {
    id: 'insurance-info',
    label: 'Insurance Info',
    active: step === DCSteps.INSURANCE_INFO,
    complete: step > DCSteps.INSURANCE_INFO,
    step: 3,
  },
  {
    id: 'confirmed-booking',
    label: 'Confirmation',
    active: false,
    complete: step === DCSteps.BOOKING_CONFIRMED,
    step: 4,
  },
];

export const CALL_DURATION_MINUTES = {
  DISCOVERY_CALL: 15,
  ASK_A_DIETITIAN: 30,
  INITIAL_SESSION: 60,
};

export const PEDIATRICS_AGE_LIMIT = 18;

// child group will be more branched in the future probably
export const AGE_GROUPS = {
  ALL: 'all_ages',
  ADULT: 'adult',
  CHILD: 'child',
};

export const NO_MATCH_STATES = {
  STATE: 'STATE',
  REASON_FOR_VISIT: 'REASON_FOR_VISIT',
  REFERRAL_PRACTICE: 'REFERRAL_PRACTICE',
};
