import { DateTime } from 'luxon';
import { matchIsValidTel } from 'mui-tel-input';

import {
  ERROR_MESSAGES,
  PEDIATRICS_AGE_LIMIT,
  TIMEOUTS,
} from '@/constants/constants';

import { INSURANCE_FORM_URL } from '../../cypress/support/constants.cy';

export const getInsuranceFormLink = (
  token: string | undefined,
  discoveryCall?: 'true'
) => {
  return `${INSURANCE_FORM_URL}?token=${token}${
    discoveryCall ? '&discoveryCall=true' : ''
  }`;
};

export const getInitialsFromName = (name: string) => {
  const matches = name.match(/\b(\w)/g);

  return matches ? matches.join('').toUpperCase() : '';
};

export const customDateOfBirthValidation = (value: any, helpers: any) => {
  if (!value || (value.isValid && !value.isValid())) {
    return helpers.error('any.invalid');
  }

  const date = DateTime.fromISO(value.toISOString());
  const earliest = DateTime.fromISO('1900-01-01');
  const latest = DateTime.fromJSDate(new Date());

  if (date > earliest && date < latest) return date;

  return value && helpers.error('any.invalid');
};

export const scrollToBottomOfPage = () => {
  // scroll to error
  setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, TIMEOUTS.XXSMALL);
};

export const scrollToTop = () =>
  window.scrollTo({ top: 0, left: 0, behavior: 'auto' });

export const customPhoneValidation = (value: string) => {
  if (matchIsValidTel(value)) return value;
  throw new Error(ERROR_MESSAGES.PHONE_FIELD);
};

// this is 3rd party mix-panel FN provided to us
export const campaignParams = () => {
  function getQueryParam(url: any, param: string) {
    // Expects a raw URL
    // eslint-disable-next-line no-empty-character-class, no-param-reassign
    param = param.replace(/[[]/, '[').replace(/[]]/, ']');
    const regexS = `[?&]${param}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results: any = regex.exec(url);

    if (
      results === null ||
      (results && typeof results[1] !== 'string' && results[1].length)
    ) {
      return '';
    }
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
  }

  const campaignKeywords: any =
    'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
  let kw = '';
  const params: any = {};
  const firstParams: any = {};
  let index: number;

  for (index = 0; index < campaignKeywords.length; index += 1) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);

    if (kw.length) {
      params[`${campaignKeywords[index]} [last touch]`] = kw;
    }
  }

  for (index = 0; index < campaignKeywords.length; index += 1) {
    kw = getQueryParam(document.URL, campaignKeywords[index]);

    if (kw.length) {
      firstParams[`${campaignKeywords[index]} [first touch]`] = kw;
    }
  }
};

export const isPediatricPatient = (dateOfBirth: DateTime) => {
  return (
    dateOfBirth >
    DateTime.now().minus({
      years: PEDIATRICS_AGE_LIMIT,
    })
  );
};

export const safeDateSafari = (dateString: string) => {
  // safari for dates with long format "2023-12-19 07:00:00 UTC" returns invalid
  // it needs "/"
  return new Date(dateString.replace(/-/g, '/'));
};
