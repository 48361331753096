import { DateTime } from 'luxon';
import React from 'react';
import useSWR from 'swr';

import { BIRTHDAY_DATE_FORMAT } from '@/constants/constants';
import type {
  AppointmentTypeValues,
  IAADPayloadProps,
  IAddCreditCardPayloadProps,
  ICancelAppointmentProps,
  IDTICallPayloadProps,
  IDTICallSPPayloadProps,
  IInitialCallPayloadProps,
  IInitialCallSPPayloadProps,
  IInsuranceFormPayloadProps,
  ILead,
  INoDietitiansProps,
  IPromoCodeValidatePayloadProps,
  IPurchaseMembershipPayloadProps,
  IRescheduleAppointmentProps,
  TrackPageFlow,
} from '@/models/Types';
import { AppConfig } from '@/utils/AppConfig';
import type { IBookAppointmentRequestType } from '@/utils/AppState';

const fetcher = (...args: any) =>
  // @ts-ignore
  fetch(...args).then((res) => {
    if (res.status === 200) return res.json();
    throw Error('An error has occurred.');
  });

const REASONS_INSURANCE_URL = `${AppConfig.apiUrl}/reasons/insurance?reasonType=primary`;
const DIAGNOSES_URL = `${AppConfig.apiUrl}/reasons/diagnosis`;
const INSURANCE_COMPANIES_URL = `${AppConfig.apiUrl}/insurance/companies`;
const BOOK_URL = `${AppConfig.apiUrl}/appointments`;
const BOOK_INITIAL_CALL_URL = `${BOOK_URL}/initial`;
const BOOK_AAD_CALL_URL = `${BOOK_URL}/ask-a-dietitian`;
const PURCHASE_MEMBERSHIP_URL = `${AppConfig.apiUrl}/membership-packages/purchase`;
const AVAILABILITY_URL = `${AppConfig.apiUrl}/availabilities`;
const SAVE_LEAD = `${AppConfig.apiUrl}/leads`;
const INSURANCE_URL = `${AppConfig.apiUrl}/insurance`;
const INSURANCE_ATTACHMENTS_URL = `${INSURANCE_URL}/card`;
const DIETITIANS_URL = `${AppConfig.apiUrl}/dietitians`;
const DIETITIANS_NO_MATCH_URL = `${DIETITIANS_URL}/no-dietitian-match`;
const MEMBERSHIP_URL = `${AppConfig.apiUrl}/membership-packages`;
const PROMO_CODE_VALIDATE_URL = `${AppConfig.apiUrl}/promo-codes/validate`;
const TRACK_PAGE_FLOW = `${AppConfig.apiUrl}/track`;

export const NETWORK_STATUS_MAP = {
  in_network: { id: 'in_network', label: 'In Network' },
  out_of_network: { id: 'out_of_network', label: 'Out of Network' },
  n_a: { id: 'n_a', label: 'N/A' },
};

const getReasonsDiscoveryUrl = (patient_dob?: string | null) =>
  `${AppConfig.apiUrl}/reasons/discovery${
    patient_dob ? `?patient_dob=${patient_dob}` : ''
  }`;

const getPatientsURL = (token: string, userId?: string) =>
  `${AppConfig.apiUrl}/patients/${token}${userId ? `?user_id=${userId}` : ''}`;

export const getICalDownloadURL = (
  title: string,
  description: string,
  start: string,
  duration: string
) =>
  `${AppConfig.apiUrl}/calendar?title=${title}&description=${description}&start=${start}&duration=${duration}`;
const getAddCardURL = (token: string | undefined) =>
  `${AppConfig.apiUrl}/patients/${token}/credit-card`;

export enum Reasons {
  discovery,
  insurance,
}

export function UseReasons(reasonType: Reasons, patientsDoB?: string | null) {
  const { data, error, isLoading } = useSWR(
    reasonType === Reasons.discovery
      ? getReasonsDiscoveryUrl(patientsDoB)
      : REASONS_INSURANCE_URL,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryInterval: 4500,
      errorRetryCount: 3,
    }
  );

  return {
    reasons: data,
    isLoading,
    isError: error,
  };
}

export function UseDiagnoses() {
  const { data, error, isLoading } = useSWR(DIAGNOSES_URL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryInterval: 4500,
    errorRetryCount: 3,
  });

  return {
    diagnoses: data,
    isLoading,
    isError: error,
  };
}

export const INSURANCE_OPTION_NA = 'N/A';
export const INSURANCE_OPTION_OTHER = 'Other';
export function UseInsuranceCompanies(includeNA = false) {
  const { data, error, isLoading } = useSWR(INSURANCE_COMPANIES_URL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryInterval: 4500,
    errorRetryCount: 3,
  });

  return {
    insuranceCompanies: includeNA ? [INSURANCE_OPTION_NA].concat(data) : data,
    isLoading,
    isError: error,
    NA_OPTION: INSURANCE_OPTION_NA,
  };
}

export function UseMembershipPackages() {
  const { data, error, isLoading } = useSWR(MEMBERSHIP_URL, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    errorRetryInterval: 4500,
    errorRetryCount: 3,
  });

  return {
    membershipData: data,
    isLoading,
    isError: error,
  };
}

export function UseAppointment(
  ticketId: string | null,
  token: string | null,
  userId?: string
) {
  const { data, error, isLoading } = useSWR(
    token && ticketId
      ? `${BOOK_URL}/${ticketId}?user_token=${token}${
          userId ? `&user_id=${userId}` : ''
        }`
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryInterval: 4500,
      errorRetryCount: 3,
    }
  );

  return {
    appointment: data,
    isLoading,
    isError: error,
  };
}

export function UseDietitians({
  reason,
  approach,
  providerId,
  referringId,
  insurance,
  customGroup,
  referringPractice,
  patientsDoB,
  state,
}: {
  reason?: string | null;
  approach?: string | null;
  providerId?: string | number | null;
  referringId?: string | null;
  insurance?: boolean;
  customGroup?: string;
  referringPractice?: string;
  patientsDoB?: string | null;
  state?: string;
}) {
  const params: any = new URLSearchParams();

  if (reason) params.append('reason', reason);

  if (approach) params.append('approach', approach);

  if (providerId) params.append('provider_id', providerId.toString());

  if (referringId) params.append('referring_id', referringId);

  if (insurance) params.append('insurance', insurance.toString());

  if (customGroup) params.append('custom_group', customGroup);

  if (referringPractice) params.append('referring_practice', referringPractice);

  if (patientsDoB) params.append('patient_dob', patientsDoB);

  if (state) params.append('state', state);

  const { data, error, isLoading } = useSWR(
    `${DIETITIANS_URL}${
      params?.size || params?.toString().length ? `/?${params.toString()}` : ''
    }`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryInterval: 4500,
      errorRetryCount: 3,
    }
  );

  return {
    dietitians: data,
    isLoading,
    isError: error,
  };
}

export function UsePatientData(token: string | null, userId?: string) {
  const { data, error, isLoading } = useSWR(
    token ? getPatientsURL(token, userId) : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryInterval: 4500,
      errorRetryCount: 3,
    }
  );

  return {
    patientData: data,
    isLoading,
    isError: error,
  };
}

interface Appointment {
  practitioner_name: string;
  slots_id: string;
  start_date: string;
  label: string;
}

interface AppointmentDate {
  date: string;
  availability: Appointment;
}

export function UseCalendar(
  type: AppointmentTypeValues,
  providerId: string,
  fetchStartDate: DateTime,
  fetchEndDate: DateTime,
  noCache?: boolean,
  state?: string,
  reason?: string
) {
  const startDate = fetchStartDate && fetchStartDate.toFormat('yyyy-MM-dd');
  const endDate = fetchEndDate && fetchEndDate.toFormat('yyyy-MM-dd');
  const requestUrl = `${AVAILABILITY_URL}?startDate=${startDate}&endDate=${endDate}&type=${type}${
    providerId ? `&providerId=${providerId}` : ''
  }${state ? `&state=${state}` : ''}${reason ? `&reason=${reason}` : ''}`;

  const random = React.useRef(Date.now());
  const requestUrlWithCacheBusting = `${requestUrl}&cacheBusting=${random.current}`;

  const { data, error, isLoading } = useSWR(
    noCache ? requestUrlWithCacheBusting : requestUrl,
    fetcher,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryInterval: 4500,
      errorRetryCount: 3,
    }
  );

  const appointmentsByDay: Array<Appointment> = [];
  const appointments: Array<AppointmentDate> = [];

  if (!error && !isLoading && data && data.availabilities) {
    const sortedAppointments = data.availabilities?.sort((a: any, b: any) => {
      if (+DateTime.fromISO(a.startDate) < +DateTime.fromISO(b.startDate))
        return -1;
      if (+DateTime.fromISO(a.startDate) > +DateTime.fromISO(b.startDate))
        return 1;
      return 0;
    });

    sortedAppointments.forEach((app: any) => {
      const date = DateTime.fromISO(app.startDate).toISODate();
      const dateKey = date.toLocaleString();
      const apptValue = {
        ...app,
        start_date: DateTime.fromISO(app.startDate),
        label: DateTime.fromISO(app.startDate).toLocaleString(
          DateTime.TIME_WITH_SHORT_OFFSET
        ),
      };

      // @ts-ignore
      if (!appointmentsByDay[dateKey]) appointmentsByDay[dateKey] = [];

      /* todo remove this filter after dedupe is implemented on backed */
      if (
        !appointmentsByDay.find(
          (appt) => appt.start_date === apptValue.start_date
        )
      )
        // @ts-ignore
        appointmentsByDay[dateKey].push(apptValue);
    });
  }

  Object.keys(appointmentsByDay).forEach((k: any) => {
    // @ts-ignore
    const sortedAvailability = appointmentsByDay[k].sort((a: any, b: any) => {
      if (+a.start_date < +b.start_date) return -1;
      if (+a.start_date > +b.start_date) return 1;
      return 0;
    });

    appointments.push({
      date: k,
      availability: sortedAvailability,
    });
  });

  return {
    appointments,
    noMatchCause: data?.noMatchCause,
    isLoading,
    isError: error,
  };
}

export async function bookAppointmentRequest(
  data: IBookAppointmentRequestType
) {
  const response = await fetch(BOOK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      start_date: data.appointment.startDate,
      provider_id: data.appointment.providerId,
      first_name: data.details.firstName,
      last_name: data.details.lastName,
      preferred_name: data.details.preferredName,
      phone_number: data.details.phoneNumber?.replace(/\s/g, ''),
      email: data.details.email,
      reason_for_visit: data.details.reason,
      slots_id: data.appointment.slots_id,
      birthday: DateTime.fromJSDate(
        new Date(data.details.dateOfBirth)
      ).toFormat(BIRTHDAY_DATE_FORMAT),
      zipcode: data.details.zipCode,
      // leaving willVerifyInsurance. There's no need to change this
      // for floodgate as this function is only ever called for DC, and
      // floodgate is for DTI only.
      payment_type:
        data.details.willVerifyInsurance === 'yes'
          ? 'payment_insurance'
          : 'payment_self_pay',
      referral_source: data.details.referral,
      referral_source_other: data.details?.referral_other,
      referral_source_physician_practice:
        data.details?.referral_source_physician_practice,
      type: data.type,
      time_zone: data.time_zone,
      research_opt_in: false,
      accepted_tos_and_hipaa: true,
      accepted_newsletter_marketing: true,
      consent_to_contact_via_sms: data.details?.consentToContactViaSMS,
      ...(data.insurance_ticket_number && {
        insurance_ticket_number: data.insurance_ticket_number,
      }),
      ...(data.user_id && { user_id: data.user_id }),
    }),
  });

  return response;
}

export async function uploadInsuranceForm(
  payload: IInsuranceFormPayloadProps | undefined
) {
  const response = await fetch(INSURANCE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function uploadInsuranceAttachments(
  formData: FormData,
  token: string | null,
  userId?: string | null
) {
  const response = await fetch(
    `${INSURANCE_ATTACHMENTS_URL}/${token}${
      userId ? `?user_id=${userId}` : ''
    }`,
    {
      method: 'POST',
      body: formData,
    }
  );

  return response;
}

export async function cancelAppointment(
  payload: ICancelAppointmentProps,
  ticketId: string | null
) {
  const response = await fetch(`${BOOK_URL}/${ticketId}/cancel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function rescheduleAppointment(
  payload: IRescheduleAppointmentProps
) {
  const response = await fetch(`${BOOK_URL}/reschedule`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function bookInitialCallApi(
  payload: IInitialCallPayloadProps | IInitialCallSPPayloadProps
) {
  const response = await fetch(BOOK_INITIAL_CALL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function bookDTICallApi(
  payload: IDTICallSPPayloadProps | IDTICallPayloadProps
) {
  const response = await fetch(BOOK_INITIAL_CALL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function bookAADCallApi(payload: IAADPayloadProps) {
  const response = await fetch(BOOK_AAD_CALL_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function purchaseMembershipApi(
  payload: IPurchaseMembershipPayloadProps
) {
  const response = await fetch(PURCHASE_MEMBERSHIP_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function saveLead(payload: ILead) {
  const response = await fetch(SAVE_LEAD, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function addPatientCreditCard(
  payload: IAddCreditCardPayloadProps
) {
  const response = await fetch(getAddCardURL(payload.user_token), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function validatePromoCode(
  payload: IPromoCodeValidatePayloadProps
) {
  const response = await fetch(PROMO_CODE_VALIDATE_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function dietitiansNoMatchRequest(payload: INoDietitiansProps) {
  const response = await fetch(DIETITIANS_NO_MATCH_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return response;
}

export async function trackIterablePage(payload: TrackPageFlow) {
  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  if (!payload.email) {
    return null;
  }

  const response = await fetch(TRACK_PAGE_FLOW, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: payload.email,
      page: payload.page,
      flow: payload.flow,
      ...(payload?.reasonForVisit && {
        reasonForVisit: payload.reasonForVisit,
      }),
    }),
  });

  return response;
}
